import { useEffect } from 'react';
import { navigate } from 'gatsby';

const Index = () => {
	useEffect(() => {
		if (localStorage.getItem('session-id')) {
			navigate('/menu');
		} else {
			navigate('/intro');
		}
	}, []);

	return null;
};

export default Index;
